module.exports = {
    cryptoList: [
        {
            label: 'BTC',
            value: 'BTC',
            description: 'Bitcoin',
            icon: "/assets/crypto/btc.svg",
        },
        {
            label: 'ETH',
            value: 'ETH',
            description: 'Ethereum',
            icon: "/assets/crypto/btc.svg",
        },
        {
            label: 'USDC',
            value: 'USDC',
            description: 'USD Coin',
            icon: "/assets/crypto/btc.svg",
        },
        {
            label: 'USDT',
            value: 'USDT',
            description: 'Tether',
            icon: "/assets/crypto/btc.svg",
        }
    ],
    fiatlist: [
        {
            label: 'USD',
            value: 'USD',
            description: 'US Dollar'
        },
    ],
    countryList: [
        {
            label: 'UAE',
            value: 'UAE'
        },
        {
            label: 'USA',
            value: 'USA'
        },
        {
            label: 'SGP',
            value: 'SGP'
        },
        {
            label: 'PHL',
            value: 'PHL'
        },
        {
            label: 'MYS',
            value: 'MYS'
        },
        {
            label: 'JPN',
            value: 'JPN'
        },
        {

            label: 'IND',
            value: 'IND',
        }
    ],
    recipientType: [
        {
            label: 'Individual',
            value: 'individual'
        },
        {
            label: 'Company',
            value: 'company'
        }
    ],
    statusMap: {
        draft: "Draft",
        initiated: "Initiated",
        fund_processing: "Payment Processing",
        fund_settled: "Payment Received",
        fund_failed: "Payment Failed",
        asset_deposited: "Initiated",
        asset_settled: "Initiated",
        partial_asset_settled: "Withdraw Partially Completed",
        asset_failed: "Payment Failed",
        failed: "Payment Failed",
        partially_completed: 'Partially Completed',
        expired: 'Expired',
    },
    payoutStatusMap: {
            initiated: "Payout Initiated",
            fund_settled: "Payout Successful",
            failed: "Failed",
            asset_settled: "Payment Released",
            asset_deposited: "Payment Released",
            asset_processing: "Payment Released",
            fund_processing: "Payment Released",
            completed: "Successful",
            approval_pending: "Approval Pending",
            rejected: "Rejected",
            expired: "Expired",
            fund_failed: "Failed",
            approved: "Approval Accepted",
    },
    prefundStatusMap: {
        initiated: "Prefund Initiated",
        completed: "Prefund Successful",
        asset_deposit_failed: "Prefund Failed",
        fund_failed: "Prefund Failed",
        fund_settled: "Payment Received",
        fund_processing: "Prefund Processing",
    },
    colorMap: {
        draft: "orange",
        initiated: "purple",
        fund_processing: "orange",
        fund_settled: "green",
        fund_failed: "purple",
        asset_deposited: "green",
        asset_settled: "green",
        partial_asset_settled: "",
        asset_failed: "red",
        asset_deposit_failed: "red",
        failed: 'red',
        partially_completed: 'blue',
        cancelled: 'red',
        expired: 'red',
        completed: 'green'
    },
    colorMapPrefund: {
        draft: "#FF9350",
        initiated: "#1A4FD6",
        fund_processing: "#FF9350",
        asset_processing: "#FF9350",
        approval_pending: "#FF9350",
        fund_settled: "#FF9350",
        fund_failed: "#E90000",
        asset_deposited: "#26A17B",
        asset_settled: "#26A17B",
        partial_asset_settled: "",
        asset_failed: "#E90000",
        failed: '#E90000',
        partially_completed: '#1A4FD6',
        cancelled: '#E90000',
        expired: '#E90000',
        rejected: '#E90000',
        completed: '#26A17B'
    },
    colorMapPayout: {
        draft: "#FF9350",
        initiated: "#1A4FD6",
        fund_processing: "#FF9350",
        asset_processing: "#FF9350",
        approval_pending: "#1A4FD6",
        fund_settled: "#26A17B",
        fund_failed: "#E90000",
        asset_deposited: "#26A17B",
        asset_settled: "#FF9350",
        partial_asset_settled: "",
        asset_failed: "#E90000",
        failed: '#E90000',
        partially_completed: '#1A4FD6',
        cancelled: '#E90000',
        expired: '#E90000',
        rejected: '#E90000',
        completed: '#26A17B',
        approved: "#092C4C"
    },
    colorMapCollections: {
        pending: {
            color: "#414141",
            bg: "#F2F2F2"
        },
        initiated: {
            color: "#E57936",
            bg: "#FFF4ED"
        },
        fund_settled: {
            color: "#1A4FD6",
            bg: "#E8EDFB"
        },
        fund_failed: {
            color: "#E90000",
            bg: "#FDE5E5"
        },
        failed: {
            color: "#E90000",
            bg: "#FDE5E5"
        },
        manual_review: {
            color: "#E90000",
            bg: "#FDE5E5"
        },
        asset_settled: {
            color: "#26A17B",
            bg: "#E9F6F2"
        },
        asset_deposited: {
            color: "#FF9350",
            bg: "#FFF4E8"
        },
        fund_processing: {
            color: "#FF9350",
            bg: "#FFF4E8"
        },
        completed: {
            color: "#1A4FD6",
            bg: "#E8EDFB"
        },
        expired: {
            color: "#E90000",
            bg: "#FDE5E5"
        },
        payment_pending: {
            color: "#414141",
            bg: "#F2F2F2"
        },
        refund_initiated: {
            color: "#E57936",
            bg: "#FFF4ED"
        }
    },
    PREFUNDING_STATUS_MAP: {
        initiated: "Prefunding Initiated",
        fund_settled: "Prefunding Successful",
        failed: "Failed",
        asset_settled: "Fiat Recieved",
        asset_deposited: "Fiat Recieving",
        fund_processing: "Fiat Processing",
        completed: "Prefunding Successful",
    },
    STATUS_MAP: {
        prefunding: {
            initiated: "Prefunding Initiated",
            fund_settled: "Fiat Recieved",
            fund_failed: "Failed",
            failed: "Failed",
            asset_settled: "Fiat Recieved", // this is not in prefunding.. we need to update this prefunding map to reflect it accordingly...
            asset_deposited: "Fiat Recieving",
            asset_processing: "Fiat Recieving",
            fund_processing: "Fiat Processing",
            completed: "Prefunding Successful",
            expired: "Expired",
        },
        collections: {
            pending: "Pending",
            initiated: "Payment Initiated",
            fund_settled: "Payment Successful",
            failed: "Failed",
            asset_settled: "Payment Recieved",
            asset_deposited: "Payment Recieving",
            fund_processing: "Payment Processing",
            completed: "Payment Successful",
            expired: "Expired",
            fund_failed: "Payment Failed",
            payment_pending: "Payment Pending",
            manual_review: "Manual Review",
            refund_initiated: "Refund Initiated",
        },
        payout: {
            "crypto_payout": {
                initiated: "Payout Initiated",
                fund_settled: "Payout Successful",
                failed: "Failed",
                asset_settled: "Crypto Delivery Initiated",
                asset_deposited: "Crypto Delivery Initiated",
                fund_processing: "Crypto Delivery Initiated",
                asset_processing: "Crypto Delivery Initiated",
                completed: "Successful",
                approval_pending: "Approval Pending",
                rejected: "Rejected",
                expired: "Expired",
                fund_failed: "Failed",

            },
            "fiat_payout": {
                initiated: "Payout Initiated",
                fund_settled: "Payout Successful",
                failed: "Failed",
                asset_settled: "Payment Released",
                asset_deposited: "Payment Released",
                asset_processing: "Payment Released",
                fund_processing: "Payment Released",
                completed: "Successful",
                approval_pending: "Approval Pending",
                rejected: "Rejected",
                expired: "Expired",
                fund_failed: "Failed",
                approved: "Approval Accepted",

            },
        },
    },
    entityType: [
        {
            label: 'Limited liability company',
            value: 'limited_liability_company'
        },
        {
            label: 'Public listed company',
            value: 'public_listed_company'
        },
        {
            label: 'Sole proprietor',
            value: 'sole_proprietor'
        },
        {
            label: 'Partnership',
            value: 'partnership'
        },
        {
            label: 'Trust',
            value: 'trust'
        },
        {
            label: 'Private foundation',
            value: 'private_foundation'
        },
        {
            label: 'Charity',
            value: 'charity'
        },
        {
            label: 'Nonprofit Organization',
            value: 'nonprofit_organization'
        },
        {
            label: 'Others',
            value: 'other'
        },
    ],
    sepaCountries: {
        "Austria": "AT",
        "Belgium": "BE",
        "Bulgaria": "BG",
        "Croatia": "HR",
        "Cyprus": "CY",
        "Czechia": "CZ",
        "Denmark": "DK",
        "Estonia": "EE",
        "Finland": "FI",
        "France": "FR",
        "Germany": "DE",
        "Greece": "GR",
        "Hungary": "HU",
        "Ireland": "IE",
        "Italy": "IT",
        "Latvia": "LV",
        "Lithuania": "LT",
        "Luxembourg": "LU",
        "Malta": "MT",
        "Netherlands": "NL",
        "Poland": "PL",
        "Portugal": "PT",
        "Romania": "RO",
        "Slovakia": "SK",
        "Slovenia": "SI",
        "Spain": "ES",
        "Sweden": "SE",
        "Switzerland": "CH",
        "United Kingdom": "GB",
        "San Marino": "SM",
        "Vatican": "VA",
        "Andorra": "AD",
        "Monaco": "MC",
        "Iceland": "IS",
        "Norway": "NO",
        "Liechtenstein": "LI"
    },
}
