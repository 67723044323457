import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Flex, HStack, useToast } from '@chakra-ui/react';
import { Formik } from "formik";
import { isUndefined } from 'lodash';

// Custom Function and Components
import SelectContactTab from './Tabs/SelectContactTab';
import SetAmountTab from './Tabs/SetAmountTab';
import SummaryTab from './Tabs/SummaryTab';
import Typography from '../../../components/Common/Typography';
import { goToNextStep, setActiveStep, setValues, getContacts, resetState, setSendAmount, setReceivedAmount } from '../../../store/slices/sendMoneySlices';
import { validationSchema } from './individualPaymentSchema';
import ApprovalPending from './Tabs/ApprovalPending';
import { createIndividualTransaction } from '../../../store/slices/transactionSlices';
import { callApiWithToken } from '../../../utils/http_common';
import IndividualPaymentLoader from './IndividualPaymentLoader';

// CSS
import '../sendmoney.css';

const IndividualPayment = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({});
  const toast = useToast();

  const formikRef = useRef();

  const { step: activeStep, firstStepComplete, secondStepComplete, loading, values: formValues } = useSelector((state) => state.sendMoney);
  const customerType = useSelector((state) => state?.login?.userDetails?.cxRoles || [])[0];
  const balanceDetails = useSelector((state) => state.sendMoney.values.walletName);
  const sendAmount = useSelector((state) => state.sendMoney.sendAmount);

  const getCustomerData = async () => {
    const { data } = (await callApiWithToken.post("/organization/details", { impersonatedOrg: 'all' })) || {};
    const settings = data?.data?.webhookResponse || {};
    setSettings(settings);
  };

  useEffect(() => {
    if (customerType === "finance_manager") {
      navigate(-1);
    }
    getCustomerData();
    dispatch(getContacts());

    return () => {
      dispatch(resetState());
    };
  }, []);

  const [tab, setTab] = useState("select-contact");

  const tabMap = {
    1: "select-contact",
    2: "set-amount",
    3: "summary"
  };

  const initialValue = {
    contactId: "",
    paymentPurpose: "",
    paymentAccountName: "",
    walletName: "",
    amount: "",
    notes: ""
  };

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  const currentValidationSchema = validationSchema[activeStep - 1];

  function handleTabChange({ tabNumber }) {
    setTab(tabMap[tabNumber]);
    dispatch(setActiveStep(tabNumber));

    // Reset States Upon Moving back Down States
    if (tabNumber === 1) {
      formikRef?.current?.setFieldValue("enteredAmount", 0);
      formikRef?.current?.setFieldValue("receivedAmount", 0);
      formikRef?.current?.setFieldValue("invoice", null);
      formikRef?.current?.setFieldValue("notes", "");
      dispatch(setSendAmount(0));
      dispatch(setReceivedAmount(0));
    } else if (tabNumber === 2) {
      formikRef?.current?.setFieldValue("invoice", null);
      formikRef?.current?.setFieldValue("notes", "");
    }
  };

  async function handleFileUpload({ file }) {
    if (isUndefined(file))
      return null;
    let timestamp = Math.floor(Date.now() / 1000);
    const filename = `invoice_${timestamp}.` + file?.name?.split('.').pop();
    const data = (await callApiWithToken.get(`/transaction/uploadFile?fileName=${filename}&fileType=${file.type}`)) || {};
    const { uploadUrl } = JSON.parse(data?.data?.data);

    try {
      await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file?.type
        },
        body: file,
      });
      return filename;
    } catch (err) {
      console.error('Error uploading to S3:', err);
      return null;
    }
  }

  async function handleSubmit(values, actions) {
    actions.setSubmitting(true);
    if (activeStep === 3) {
      try {
        const file = values.invoice;
        const uploadedFileName = await handleFileUpload({ file });
        // Make API Call for Creating Transaction here
        const payload = {
          type: formValues?.paymentAccountName?.type || "",
          contactId: formValues?.contactId?.value || "",
          accountId: formValues?.paymentAccountName?.value || "",
          purpose: formValues?.paymentPurpose?.value || "",
          userWalletType: formValues?.walletName?.value || "",
          depositAmount: sendAmount,
          depositCurrency: formValues?.walletName?.label || "",
          withdrawCurrency: formValues?.paymentAccountName?.currency || "",
          additionalDetails: {
            notes: formValues?.notes || values?.notes || ""
          },
          quoteId: formValues?.quoteId || "",
          invoice: uploadedFileName
        };

        dispatch(createIndividualTransaction(payload)).then((res) => {
          const { isSuccess = false } = res?.payload?.data || {};
          const { message = "Something went wrong" } = res?.payload?.error || {};

          if (isSuccess) {
            handleToast("Transaction Created Successfully", 'success');
            dispatch(goToNextStep());
          }
          else {
            handleToast(message, 'error');
          }
        });
      }
      catch (err) {
        handleToast(err?.message || "Internal Error", 'error');
      }
    } else {
      const roundedDegree = balanceDetails?.label === "BTC" ? 5 : 2;
      if (Number(Number(balanceDetails?.balance || 0).toFixed(roundedDegree)) < Number(Number(values.enteredAmount || 0).toFixed(roundedDegree)) && activeStep === 2) {
        handleToast("Insufficient Balance", 'error');
        actions.setSubmitting(false);
        return;
      }

      dispatch(goToNextStep());
      let valuesToBeUpdated = values;

      if (activeStep === 2) {
        valuesToBeUpdated = ({
          ...formValues
        });
        valuesToBeUpdated.enteredAmount = values?.enteredAmount || 0;
        valuesToBeUpdated.quoteId = values?.quoteId || "";
        valuesToBeUpdated.receivedAmount = values?.receivedAmount || "";
      }
      dispatch(setValues(valuesToBeUpdated));
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    loading ? <IndividualPaymentLoader /> :
      <Box className='sm-main-card'>
        <Typography type='largeheader' weight='bold' >
          Individual Payment
        </Typography>
        <HStack display="flex" flexDirection="row" alignItems="flex-start">
          {activeStep !== 4 ?
            <Flex mt={'30px'} ml={'40px'} gap={6}>
              <Flex direction={'column'} gap={7} borderLeft={"2px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"136px"} justify={"center"} w={"185px"}>
                <Button onClick={() => handleTabChange({ tabNumber: 1 })} _hover={{}} _active={{}} className='stepper-button' >
                  <Typography color="primary" colorweight={activeStep === 1 ? "500" : "200"} type='body' weight='bold' >
                    Select Contact
                  </Typography >
                </Button>
                <Button onClick={() => handleTabChange({ tabNumber: 2 })} _hover={{}} _active={{}} className='stepper-button' isDisabled={!firstStepComplete} >
                  <Typography color="primary" colorweight={activeStep === 2 ? "500" : "200"} type='body' weight='bold' >
                    Set Amount
                  </Typography >
                </Button>
                <Button onClick={() => handleTabChange({ tabNumber: 3 })} _hover={{}} _active={{}} className='stepper-button' isDisabled={!secondStepComplete} >
                  <Typography color="primary" colorweight={activeStep === 3 ? "500" : "200"} type='body' weight='bold' >
                    Summary
                  </Typography >
                </Button>
              </Flex>
            </Flex> :
            null
          }
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validationSchema={currentValidationSchema}
            innerRef={formikRef}
          >
            {({ isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                {
                  activeStep === 1 ? <SelectContactTab /> :
                    activeStep === 2 ? <SetAmountTab /> :
                      activeStep === 3 ? <SummaryTab isSubmitting={isSubmitting} settings={settings} /> :
                        activeStep === 4 ? <ApprovalPending /> :
                          <>{navigate('/send-money')}</>
                }
              </form>
            )}
          </Formik>
        </HStack>
      </Box>
  );
}

export default IndividualPayment;
