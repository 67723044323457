import { Badge, Stack, Text, Box, HStack, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { BiReset } from 'react-icons/bi'
import { startCase, round } from 'lodash';

import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import Loader from "../../../components/Loader";
import { colorMap,  prefundStatusMap } from "../../../constants";
import OrderService from '../../../services/createOrderService';
import GridV2 from "../../../utils/agTablesV2";

const PrefundOrders = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);
    const isLoading = useSelector((state) => state?.order?.loading);
    const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);

    const USformatter = new Intl.NumberFormat('en-US');

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2000,
    };

    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

    const getOrderData = async (filters) => {
        filters["orgId"] = orgId;
        filters["type"] = ["crypto_prefund", "fiat_prefund"];
        const responseData = await OrderService.getOrder({ filters });
        return responseData;
    }

    const columns = [
        {
            headerName: "Prefund ID",
            field: "orderId",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => {
                return <Link style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>
            },
        },
        {
            headerName: "Order Status",
            field: "status",
            customerVisibility: true,
            enableRowGroup: true,
            filterParams: {
                applyMiniFilterWhileTyping: true,
            },
            cellRenderer: ({ value }) => {
                const newStatus = prefundStatusMap[value] || value;
                const color = colorMap[value];
                return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
            },
        },

        {
            headerName: "Created Date",
            field: "createdAt",
            filterParams: filterParams,
            customerVisibility: true,
            enableRowGroup: false,
            cellDataType: 'date',
            width: '220',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? moment(value).format("DD-MMM-YYYY @ h:mm A") : "N/A"}</Text>
            ),
        },   
        {
            headerName: "Deposit Amount", field: "depositAmount", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        
        {
            headerName: "Deposit Currency", field: "depositCurrency", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        
        {
            headerName: "Prefund Wallet Address", field: "walletAddress", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        }
    ];

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >

            {!isLoading ? <Box >
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                  
                </HStack>
                <GridV2 columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} />
            </Box> : <Loader />}
        </Stack>
    );
};

export default PrefundOrders;
