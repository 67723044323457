import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/button';
import { useFormikContext } from "formik";
import { Box, Flex, HStack, Stack, VStack } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { GoUpload } from "react-icons/go";
import { HiOutlineLightBulb } from "react-icons/hi";
import { isUndefined } from 'lodash';

// Custom Function and Components
import Typography from '../../../../components/Common/Typography';
import { TextArea } from '../../../../components/Common/InputComponents/InputComponents';
import { goToPreviousStep } from '../../../../store/slices/sendMoneySlices';
import { getDataFromSession } from '../../../../utils/session-storing-utils';
import { currencyFormatter } from '../../../../utils/formatter';

// CSS
import '../../sendmoney.css';


const SummaryTab = (props) => {
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const toast = useToast();
  const { setFieldValue } = useFormikContext();

  const contactDetails = useSelector((state) => state.sendMoney.values)?.contactId;
  const paymentAccountDetails = useSelector((state) => state.sendMoney.values)?.paymentAccountName;
  const paymentPurpose = useSelector((state) => state.sendMoney.values)?.paymentPurpose;
  const sendAmount = useSelector((state) => state.sendMoney.sendAmount);
  const receivedAmount = useSelector((state) => state.sendMoney.receivedAmount);
  const balanceDetails = useSelector((state) => state.sendMoney.values.walletName);
  const transactionLoading = useSelector((state) => state.transaction.loading || false);
  const loading = props.isSubmitting || false;
  const { isInvoiceMandatory = true } = props?.settings || {};

  const [quotesState, setQuotesState] = useState(true);
  const [fileDetails, setFileDetails] = useState("png, jpeg, pdf only");

  const fxRate = Number(getDataFromSession("payout-quotes-data", "fxRate"));
  const processingFee = Number(getDataFromSession("payout-quotes-data", "processingFee"));
  const otherFee = Number(getDataFromSession("payout-quotes-data", "otherFee"));

  function handleBack() {
    dispatch(goToPreviousStep());
  }

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  const allowedFileTypeMap = {
    "application/pdf": "pdf",
    "image/png": "png",
    "image/jpeg": "jpeg"
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (isUndefined(allowedFileTypeMap[file.type])) {
      handleToast("File Should be png, jpeg, pdf only", "error");
      return;
    }

    setFieldValue("invoice", file);
    setFileDetails(file.name);
  };

  return (
    <HStack display={"flex"} alignItems={"flex-start"} gap="16px" >
      <Flex className="summary-main-card" >
        <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
          <Typography type='body' weight='medium' color='primary' >
            Contact Details
          </Typography>
        </HStack>
        <HStack w={"100%"} display={"flex"} justifyContent={"space-between"}>
          <Box h={"56px"} display={"flex"} flexDirection={"column"} gap={"10px"}>
            <Typography type='description' weight='regular' color='secondary' >
              Contact Name
            </Typography>
            <Typography type='description' weight='regular' color='secondary' colorweight='800' >
              {contactDetails?.label || ""}
            </Typography>
          </Box>
          <Box h={"56px"} display={"flex"} flexDirection={"column"} gap={"10px"}>
            <Typography type='description' weight='regular' color='secondary' >
              Account
            </Typography>
            <Typography type='description' weight='regular' color='secondary' colorweight='800' >
              {paymentAccountDetails?.label || ""}
            </Typography>
          </Box>
        </HStack>
        <HStack w={"100%"} display={"flex"} justifyContent={"space-between"}>
          <Box h={"56px"} display={"flex"} flexDirection={"column"} gap={"10px"}>
            <Typography type='description' weight='regular' color='secondary' >
              Payment Purpose
            </Typography>
            <Typography type='description' weight='regular' color='secondary' colorweight='800' >
              {paymentPurpose?.label || ""}
            </Typography>
          </Box>
        </HStack>
        <Box border={"1px solid #F2F2F2"} w={'100%'} ></Box>
        <VStack w={"100%"} display={"flex"} flexDirection={'column'} gap={"8px"} alignItems={'flex-start'}>
          <Typography type='body' weight='medium' color='primary' >
            Additional Details
          </Typography>
          <Typography type='description' weight='regular' color='secondary' colorweight='800' >
            Add Note
          </Typography>
          <TextArea
            name="notes"
            placeholder="Add a note"
            h={"96px"}
          />
          <Box marginTop={"8px"}>
            <input
              type="file"
              accept="pdf"
              style={{ display: 'none' }}
              ref={fileInput}
              onChange={handleFileUpload}
            />
            <Typography type='description' weight='regular' color='secondary' colorweight='800' >
              Upload Invoice
              {isInvoiceMandatory && <span style={{ color: 'red' }} > *</span>}
            </Typography>
            <Button variant={"secondary"} size={"sm"} padding={"9px 16px 9px 16px"} w={"100px"} h={"40px"} border={"1px solid #E4E4E4"} marginTop={"5px"} marginBottom={"5px"} rightIcon={<GoUpload />} onClick={() => fileInput.current.click()} >
              Upload
            </Button>
            <Typography type='description' weight='regular' color='secondary' colorweight='400' >
              {fileDetails}
            </Typography>
          </Box>
        </VStack>
        {/* {balanceDetails.balance < enteredAmount &&
          <Box className='summary-caution-box' >
            <Box width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"flex-start"} gap={"8px"} >
              <Box>
                <HiOutlineLightBulb className='summary-caution-icon' />
              </Box>
              <Typography type='description' weight='regular' >
                You dont have sufficient balance in your {balanceDetails?.label || ""} Currency wallet right now, you can still submit this txn. Please ensure you have sufficient funds in the next 48 hours to process this txn.
              </Typography>
            </Box>
          </Box>
        } */}
        <Stack direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
          <Button variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"} onClick={handleBack}>
            Back
          </Button>
          <Button variant={"primary"} type="submit" size={"sm"} p={"9px 16px 9px 16px"} isLoading={loading || transactionLoading} >
            Confirm
          </Button>
        </Stack>
      </Flex>

      <Flex className='summary-side-card' flexDirection={"column"}>
        <Typography type='title' weight='bold' color='headertext' >
          Summary
        </Typography>
        <Box display={"flex"} flexDirection={'column'} gap={'12px'} >
          <HStack w={"100%"} display={"flex"} justifyContent={'space-between'} >
            <Typography type='description' weight='regular' color='secondary' >
              They receive
            </Typography>
            <Typography type='description' weight='regular' color='headertext' >
              {paymentAccountDetails?.currency === "BTC" ? currencyFormatter(receivedAmount, 5) : currencyFormatter(receivedAmount)} {paymentAccountDetails?.currency || ""}
            </Typography>
          </HStack>
          <HStack w={"100%"} display={"flex"} justifyContent={'flex-end'} >
            <Typography type='description' weight='regular' color='primary' textDecoration={"underline"} cursor={"pointer"} onClick={() => { setQuotesState((prev) => !prev); }} >
              {quotesState ? "Hide fee" : "Show fee"}
            </Typography>
          </HStack>
          {quotesState &&
            <>
              <HStack w={"100%"} display={"flex"} justifyContent={'space-between'} >
                <Typography type='description' weight='regular' color='secondary'>
                  FX Rate:
                </Typography>
                <Typography type='description' weight='regular' >
                  1 {balanceDetails?.label || ""} = {paymentAccountDetails?.currency === "BTC" ? currencyFormatter(fxRate, 5) : currencyFormatter(fxRate)} {paymentAccountDetails?.currency || ""}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={'space-between'} >
                <Typography type='description' weight='regular' color='secondary'>
                  Payout fee:
                </Typography>
                <Typography type='description' weight='regular' >
                  {balanceDetails?.label === "BTC" ? currencyFormatter(processingFee, 5) : currencyFormatter(processingFee)} {balanceDetails?.label || ""}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={'space-between'} >
                <Typography type='description' weight='regular' color='secondary'>
                  Other fees:
                </Typography>
                <Typography type='description' weight='regular' >
                  {balanceDetails?.label === "BTC" ? currencyFormatter(otherFee, 5) : currencyFormatter(otherFee)} {balanceDetails?.label || ""}
                </Typography>
              </HStack>
            </>
          }
          <Box border={"1px solid #B3B3B3"} w={"100%"}  ></Box>
        </Box>
        <HStack w={"100%"} display={"flex"} justifyContent={'space-between'} >
          <Typography type='description' weight='regular' >
            You pay
          </Typography>
          <Typography type='title' weight='regular' color='headertext' >
            {balanceDetails?.label === "BTC" ? currencyFormatter(sendAmount, 5) : currencyFormatter(sendAmount)} {balanceDetails?.label || ""}
          </Typography>
        </HStack>
      </Flex>
    </HStack>
  );
}

export default SummaryTab;